@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.container-home {
    background-image: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../../images/bgi_highway.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.home {
    &__text-container {
        &--primary { 
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 6rem;
            text-align: center;
            transform: translateX(-50%);
            width: 100%;

            @media only screen and (max-width: 850px) and (max-height: 425px) {
                top: 40%;
            }

            @include respond(sm-phone) {
                font-size: 7rem;
                top: 40%;
            }
        }

        &--secondary {
            font-size: 2rem;
            position: absolute;
            top: 65%;
            left: 50%;
            transform: translateX(-50%);

            @include respond(sm-phone) {
                top: 68%;
            }
        }
    }

    &__title {

        @include respond(sm-phone) {
            line-height: 1.1;

            @include respond(phone){
                line-height: 1.3;
            }
        }

        hr {
            display: none;

            @include respond(sm-phone) {
                display: block;
                border-width: .2rem;
                border-color: $color-primary;
                margin: 0 auto;
                width: 20%;
            }
        }
    
        span {
            color:$color-primary;
    
            @include respond(sm-phone) {
                display: none;
            }
        }
    }

    &__list {
        display: flex;
        list-style: none;

        &-item {
            padding: 2rem;
        }
    }
}

