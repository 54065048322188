@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.container-contact {
    position: relative;
    background-image: linear-gradient(to right, rgba(0,0,0,.8), rgba(0,0,0,.8)), url('../../images/technology-bgi.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.contact {
    display: grid;
    grid-template-columns: 25rem 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin: 0 auto;
    padding-bottom: 10rem;
    width: 60vw;
   
    @include respond(tab-land) {
        grid-column-gap: 2rem;
        width: 80vw;
    }

    @media only screen and (max-width: 850px) and (max-height: 425px) {
        padding-bottom: 5rem;
    }

    @include respond(mini-tab-port) {
        grid-template-columns: 100%;
        padding-bottom: 5rem;
        width: 60vw;
    }

    @include respond(sm-phone) {
        grid-row-gap: 4rem;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        width: 80vw;
    }

    &__page-header {
        grid-column: 1 / -1;
        justify-self: center;
    }

    &__section-header {
        margin-bottom: 1rem;
        text-align: center;
    }
    
    &__methods {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;

        @include respond(mini-tab-port) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-evenly;
        }
    }

    &__link {
        margin-bottom: 3rem;
    }

    &__icon {
        height: 4rem;
        width: 4rem;
        transition: all .2s;

        &:last-child:hover {
            transform: scale(1.2);
        }
    }

    &__phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
    }

    &__phone-text {
        font-size: 1.2rem;
    }

    &__message-display {
        color: $color-primary;
        font-size: 1.2rem;
        margin: 1rem auto;
        padding-bottom: -1rem;
        text-align: center;
    }
}