.form {
    width: 100%;

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__group {
        line-height: 1rem;
        padding-right: .75rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__input,
    &__select,
    &__text-area {
        background-color: $color-light-grey;
        border: none;
        border-radius: .15rem;
        color: darken($font-color-secondary, 40%);
        font-family: inherit;
        font-size: .8rem;
        margin-bottom: .25rem;
        width: 100%;
    }

    &__input,
    &__select {
        height: 1.75rem; 
    }

    &__input {
        padding-left: .5rem;

        &:focus {
            border-bottom: .1rem solid $color-primary;
            outline: none;
        }

        &:focus:invalid {
            border-bottom: .1rem solid #af0909;
            outline: none;
        }

        &::-webkit-input-placeholder {
            color: $font-color-secondary;
        }
    }

    &__select {

        &:focus {
            border-bottom: .1rem solid $color-primary;
            outline: none;
        }
    }

    &__text-area {
        height: 7.5rem;
        padding: .5rem;

        &:focus {
            border-bottom: .1rem solid $color-primary;
            outline: none;
        }

        &:focus:invalid {
            border-bottom: .1rem solid #af0909;
            outline: none;
        }

        &::-webkit-text-area-placeholder,
        &::placeholder {
            color: darken($font-color-secondary, 10%);;
        } 
    }

    &__label {
        font-size: .8rem;
        padding: 0 .25rem;
        line-height: 1rem;
        margin-left: .2rem; 
        transition: all .3s;  
    }

    &__input:placeholder-shown + &__label,
    &__text-area:placeholder-shown + &__label {
        //the opacity is what we can manipulate to make it dissappear and reappare below the input field
        opacity: 0;
        visibility: hidden;
        transform: translateY(-2rem);
    }

    &__button-row {
        display: flex;
        justify-content: center;
    }
}