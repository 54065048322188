@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.container-portfolio {
    position: relative;
    background-image: linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../../images/portfolio-bgi2.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.project {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(2, max-content);
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    justify-content: center;
    margin: 2rem auto;
    padding-bottom: 10rem;

    @media only screen and (max-width: 64em) and (orientation: portrait) {
        padding-bottom: 20rem;
    }
    @media only screen and (max-width: 64em) and (orientation: landscape) {
        padding-bottom: 10rem;
    }

    @include respond(tab-port) {
        padding-bottom: 15rem;
    }

    @include respond(phone) {
        grid-template-columns: max-content;
    }

    &__container {
        width: 25rem;
        height: 23rem;

        @include respond(mini-tab-port) {
            width: 20rem;
            height: 18rem;
        }

        @include respond(phone) {
            width: 35rem;
            height: 31rem;
        }

        &:nth-child(1) {
            background-image: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)),
                url(../../images/portfolioBGIs/resilient_resinBGI.jpg);
            background-size: cover;
            background-position: left;
        }
 
        &:nth-child(2) {
            background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)),
            url(../../images/portfolioBGIs/magnolia-bgi_lowSat.jpg);
            background-size: cover;
            background-position: center;
        }

        &:nth-child(3) {
            background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)),
            url(../../images/portfolioBGIs/tattooarms_bgi.jpg);
            background-size: cover;
            background-position: center;
        }
 
        &:nth-child(4) {
            background-image: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)),
            url(https://i.makeagif.com/media/3-11-2019/swKc_s.gif);
            background-size: cover;
            background-position: center;
        }

    }

    &__wrapper {
        // border: 2px solid $color-primary;
        position: relative;
        width: 25rem;
        height: 23rem;
        perspective: 100rem;
        overflow: hidden;

        @include respond(mini-tab-port) {
            width: 20rem;
            height: 18rem;
        }

        @include respond(phone) {
            width: 35rem;
            height: 31rem;
        }

        // flips back over for description
        &:hover .project__side--front {
            transform: rotateY(-180deg);
        }
    
        &:hover .project__side--back {
            transform: rotateY(0);
        }
    }

    &__side {
        backface-visibility: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: auto;

        transition: all .8s;
        
        // &--front {
            //do something about showing that need to click to see back on touch screens
        // }

        &--back {
            background-color: $color-lighter-black;
            font-size: 1.3rem;
            line-height: 2.5rem;
            padding: 1rem;
            transform: rotateY(180deg)
        }

    }

    &__back-text {
        font-size: 1.2rem;
        line-height: 1.6rem;

        @include respond(tab-land) {
            line-height: 1.3rem;
        }
        @include respond(mini-tab-port) {
            font-size: .9rem;
            line-height: 1.1rem;
        }
        
        @include respond(phone) {
            font-size: 1.8rem;
            line-height: 1.8rem;
        }
    }

    &__header {
        font-size: 3rem;
        margin: 4rem 2rem;
        padding: .5rem;

        @include respond(mini-tab-port) {
            font-size: 2.4rem;
        }
        
        @include respond(phone) {
            font-size: 3rem;
        }
    }

    &__image {
        width: 100%;
    }

    &__cta {
        background-color: rgba(255,255,255,.2);
        height: 4rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1000;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    
        @include respond(phone) {
            height: 6rem;
        }
    }

    &__btn {
        padding: .5rem 1rem;
        transition: all .2s;

        &:hover {
            transform: scale(1.2);
        }
    }
    

    &__keywords {
        // background-color: white;
        color: $color-primary-dark;
        font-size: 1rem;
        padding-top: .2rem;
        padding-left: .2rem;
        text-transform: uppercase;
        white-space: nowrap;
    }

    // &__wrapper svg,
    // &__wrapper svg rect {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     fill: transparent;
    // }

    // &__wrapper svg rect {
    //     stroke: $color-primary;
    //     stroke-width: 6;
    //     stroke-dasharray: 400;
    //     animation: animate 4s linear infinite;
    // }

    // @keyframes animate {
    //     0% {
    //         stroke-dashoffset: 800;
    //     }

    //     100% {
    //         stroke-dashoffset: 0
    //     }
    // }

}