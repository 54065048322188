.modal {
    // this makes the entire page dark, and then put the main modal class on top
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 199;

    &--display {
        display: block;
    }

    &--hidden {
        display: none;
    }

    &__main {
        position:fixed;
        top: 50%;
        left: 50%;
        background: $color-light-grey;
        border: .1rem solid $color-primary;
        color: $font-color-secondary;
        font-size: 1rem;
        height: auto;
        max-height: 90vh;
        overflow: auto;
        padding: 1rem;
        width: 80%;
        transform: translate(-50%,-50%);
        z-index: 200;
        animation: animatetop .3s ease-in;

    }

    @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
    }

    &__text-header {
        margin-bottom: .75rem;  
    }

    &__body-about {
        display: flex; 
        margin: 2rem; 
        margin-right: 5rem;

        @include respond(phone){
            flex-direction: column;
            align-items: center;
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    &__image {
        width: 25%;
        height: 25%;
        border-radius: 100rem;

        @include respond(phone){
            margin-bottom: 2rem;
            width: 50%;
            height: 50%;
        }
    }

    &__text-area {
        font-size: 1rem;
        margin-left: 1rem;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }

    &__button {
        margin-right: 5rem;

        @include respond(tab-port){
            margin-right: 2rem !important;
        }

        @include respond(phone){
            margin-right: 1rem !important;
        }
    }
}

.resume {
    &__header {
        margin-left: 5rem;
        margin-top: 2rem;

        @include respond(tab-port){
            margin-left: 2rem;
        }

        @include respond(phone) {
            margin-left: 1rem;
        }
    }

    &__section-head {
        text-align: center;
        margin: .5rem 0;
    }

    &__section-text {
        margin: 0 5rem;

        @include respond(tab-port){
            margin: 0 2rem;
        }

        @include respond(phone) {
            margin: 0 1rem;
        }
    }

    &__skills {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        justify-items: center;
        margin-top: 1rem;;
        text-align: center;

        @include respond(phone){
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__skills-header {
        font-size: .9rem;
    }

    &__list {
        padding: .5rem;
        padding-left: 2rem;
        line-height: 1.4rem;
    }

    &__link {
        margin-left: 5rem;
        margin-right: auto;

        @include respond(tab-port){
            margin-left: 2rem;
        }

        @include respond(phone) {
            margin-left: 1rem;
        }
    }
}
