@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import '../../client/src/sass/variables.scss';
@import '../../client//src/sass/mixins.scss';
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  color: $font-color-primary;
  line-height: 1;
  font-size: 1rem; 
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include respond(tab-port) {
    font-size: .8rem;
  }
  @include respond(phone) {
    font-size: .6rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
