.icon {
    fill: $color-primary;

    @include respond(mini-tab-port) {
        width: 2rem;
        height: 2rem
    }

    @include respond(phone) {
        width: 3.2rem;
        height: 3.2rem;
    }

    &--skills {
        width: 7rem;
        height: 7rem;
        margin: 1rem;

        @include respond(mini-tab-land) {
            width: 6rem;
            height: 6rem;
        }

        @include respond(tab-port) {
            width: 7rem;
            height: 7rem;
        }

        @include respond(mini-tab-port) {
            width: 6rem;
            height: 6rem;
        }

        @include respond(sm-phone) {
            width: 7rem;
            height: 7rem;
        }

        @media only screen and (max-width: 375px) {
            width: 6rem;
            height: 6rem;
        }
    }

    &--enlarge {
        width: 2rem;
        height: 2rem;
    }

    &--disabled {
        fill: lighten($color-primary, 15%);
    }
}