@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.nav__bottom {
    background-color: $color-lighter-black;
    height: 5rem;
    font-size: 2rem;
    padding: 1rem 4rem;
    position: fixed;
    bottom: 0;
    width: 100%;

    @include respond(sm-phone) {
        padding: 1rem 2rem;
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: space-between;
    }
}