h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.4rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
    line-height: 1.3;
}

h6 {
    font-size: .8rem;
    line-height: 1rem
}

p {
    line-height: 1.3;
    padding: .5rem 0;
}

th,
td {
    text-align: center;
    line-height: 1.3rem;
    
}

th {
    padding: 0 2rem .5rem 2rem;
    font-size: 1rem;
}