#logo {
    width: 5rem;
    height: 5rem;
    // width: 100%;
    // height: auto;
}

.st0 {
    fill: #6DB4C7;

}

.st1 {
    fill: none;
    stroke: #6DB4C7;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
}