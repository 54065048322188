.link {
    text-decoration: none;
    color: $color-primary;

    &:hover {
        color: darken($color-primary, 15%);
    }
}

.page-link {
    text-decoration: none;
    color: $font-color-primary;
  
    &:hover {
      color: darken($color-primary, 15%);
    }
}

.active {
    color: $color-primary
}