.margin-top-small {
    margin-top: 1rem;
}

.margin-bottom-small {
    margin-bottom: 1rem;
}

.margin-bottom-medium {
    margin-bottom: 2rem;
}

.center {
    text-align: center;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}