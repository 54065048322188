.page {
    position: absolute;
    //putting these all at zero makes its so that the positioning isn't affected, displays the same
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.page-enter {
    opacity: 0;
    transform: scale(0);
  
}
  
.page-enter-active {
    opacity: 1;
    transform: scale(1);
    // transform: translate(0);
    transition: opacity 500ms, transform 500ms;
}
  
.page-exit {
    opacity: 1;
    transform: scale(1);
    // transform: translateY(-100%);
   
}
  
.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
}