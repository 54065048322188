.btn {
    font-family: inherit;
    border: none;
    border-radius: .25rem;
    font-size: .8rem;
    color: white;
    padding: .5rem 1rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);

    &:focus {
        outline: none;
    }

    &__primary {
        background-color: $color-primary;
        transition: all .2s;

        &:hover {
            background-color: darken($color-primary, 15%);
        }

        &:active {
            transform: translateY(.5rem);
        }
    }   

    &__cancel {
        background-color: gray;
        margin-left: 1rem;
        transition: all .2s;

        &:hover {
            background-color: darken(gray, 15%);
        }

        &:active {
            transform: translateY(.5rem);
        }
    }

    &__flat {
        background: transparent;
        border-bottom: 2px solid transparent;
        background: transparent;
        border: none;
        color: $font-color-secondary;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        padding: 1rem .5rem;
        transition: all .2s;

        @media only screen and (max-width: 450px) {
            padding: .5rem .25rem;
        }

        &:hover {
            border-bottom: 2px solid $color-primary;
        }

        &:active,
        &:focus {
            outline: none;
        }
    }
}