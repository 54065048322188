@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.container-skills {
    position: relative;
    background-image: linear-gradient(to right, rgba(0,0,0,.8), rgba(0,0,0,.8)), url('../../images/skills-bgi.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.skills {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2.5rem;
    margin: 2rem auto;
    padding-bottom: 4rem;
    width: 75vw;
   
    @include respond(tab-port) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include respond(sm-phone) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90vw;
    }

    &__wrapper {
        border-radius: .4rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--1 {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
        }

        &--2 {
            grid-column: 3 / 4;
            grid-row: 3 / 4;
        }

        &--3 {
            grid-column: 4 / 5;
            grid-row: 3 / 4;

            @include respond(tab-port) {
                grid-column: 1 / -1;
                grid-row: 4 / 5;
                justify-content: center;
            }
        }
    }
}