//mobile responsiveness
@mixin respond($breakpoint) {

    @if $breakpoint == sm-phone {
        @media only screen and (max-width: 26.56em) { @content }; //425px
    }

    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; //600px
    }

    @if $breakpoint == mini-tab-port {
        @media only screen and (max-width: 46.88em) { @content }; //700px
    }

    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; //900px
    }

    @if $breakpoint == mini-tab-land {
        @media only screen and (max-width: 64em) { @content }; //1024px
    }

    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; //1200px
    }

    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content }; //1800px
    }

}