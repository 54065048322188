@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &__logo-wrapper {
        background-color: $color-light-grey;
        cursor: pointer;
        height: 8rem;
        margin-left: 4rem;
        padding: .5rem;
        padding-top: 1.75rem;
        width: 6.5rem;
        
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;

        &:hover {
            // height: 6rem;
            padding-left: .5rem;
        }  
    }

    &__text-wrapper {
        display: none;
        background-color: $color-light-grey;
        font-size: 1.5rem;
        height: 8rem;
        width: 0;
        // padding: 1rem;
        transition: all .2s;
        //transition: width cubic-bezier(.17,.67,.83,.67) .5s;

        &:hover {
            display: flex;
            align-items: center;
            // if remove this here, leaves the links in white
            //kind of cool may remove later
            width: 100%;
            height: 8rem;
        }
    }

    &__list {
        display: flex;
        list-style: none;
        
        @media only screen and (max-width: 450px) {
           height: 100%;
           margin-left: 1rem;
           flex-direction: column;
           align-items: center;
           justify-content: center;
        }
    }

    &__list-item {
        padding: 1rem;  
        @media only screen and (max-width: 450px) {
            padding: .25rem;
         }
    }

    &__title {
        margin-right: 4rem;
        margin-left: auto;

        &--flyout {
            color: $color-primary;

            @include respond(sm-phone) {
                margin-right: 1rem;
                margin-left: 2rem;
            }
        }
    }

    &__logo-wrapper:hover + &__text-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__logo-wrapper:hover ~ &__title,
    &__text-wrapper:hover + &__title {
        display: none;
    }
}